<template>
  <div class="app-container">
    <div class="content" v-loading='initLoading'>
      <el-form ref="mainForm" :rules="rules" :model="tmpData" label-width="5rem" class="form1" :label-position="'top'">

        <p class="nowCompany">当前公司：{{nowCompany}}</p>

        <el-form-item label='账号' prop='phone' class="phone">
            <el-input v-model='tmpData.phone' maxlength="11"  class="rate" placeholder="请输入手机号" disabled/>
        </el-form-item>

        <el-form-item label='成员角色' required="">
            <el-radio-group v-model="tmpData.roleId">
                <el-radio class="radio" :label="item.id" v-for="(item,index) in list" :key="index">{{item.name}}</el-radio>
            </el-radio-group>
        </el-form-item>

        <p class="tips">不知道角色权限？ <el-link class="link" @click="toPath">立即查看 <i class="iconfont iconicon2-02"></i></el-link></p>

        <div class="button">
          <el-button type="primary" class="btn" @click="submitForm" :loading='isLoading'>保存</el-button>
          <el-button class="btn" @click="back">取消</el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
  export default {
    name: "editCurrency",
    components:{
    },
    data() {
      return {
        nowCompany: this.getToken('nowCompany'),
        list:[], //角色列表
        tmpData: {
            roleId:'',
            phone:'',
        },
        rules: {
            phone: [
                { required: true, message: "请输入手机号码" , trigger: 'blur' },
                // { pattern: '^1[3|4|5|7|8|9][0-9]{9}$', message: '手机格式不正确', trigger: 'blur' }
            ],
        },

        isLoading : false,   // 提交等待框
        initLoading : false, // 初始化模态框
      };
    },
    methods: {
        submitForm() {
            this.$refs.mainForm.validate((valid) => {
                if (valid) {
                    this.isLoading = true;
                    let data = { companyId:this.getToken('companyId') , ...this.tmpData , userId: this.$route.query.id ,};
                    this.$store.dispatch("EditPermissions", data).then(res => {
                        if (res.success) {
                            this.isLoading = false;
                            this.$router.push('/permission/permission')
                        } else {
                            this.isLoading = false;
                            this.$message.error(res.msg)
                        }
                        }).catch(err => {
                        this.isLoading = false
                    })
                }
            });
        },
        //页面跳转
        toPath(){
          this.$router.push({ path: "/permission/role" })
        },
        back() {
            this.$router.push('/permission/permission');
        },
        init(){  //初始化内容
            this.initLoading = true
            this.$store.dispatch("FindPermissionsById", {
                companyId:this.getToken('companyId'),
                userId: this.$route.query.id,
                }).then(res => {
                this.initLoading = false
                if (res.success) {
                    this.tmpData = res.data;
                } else {
                    this.$message.error(res.msg)
                }
            }).catch(err => {
            this.initLoading = false
            })
        },
        //查询角色列表
        getRoleList() {
            this.initLoading = true;
            this.$store.dispatch("FindRoleList", {
            companyId:this.getToken('companyId'),
            userId : this.$route.query.id,
            }).then(res => {
            if (res.success) {
                this.initLoading = false;
                this.list = res.data;
            } else {
                this.initLoading = false;
                this.$message.error(res.msg)
            }
            })
        },
        },
        mounted(){
            this.getRoleList();
            this.init();
        }
    };
</script>

<style lang='less' scoped>
    .content {
        padding: 0 20px;
        height: calc(100%);
        margin: 0 auto;
        background: #fff;
    .nowCompany {
        border-bottom: 1px solid #DCDCDC;
        text-align: center;
        font-weight: 700;
        font-size: 18px;
        position: relative;
        line-height: 70px;
        margin: 0;
        .btn-item {
            position: absolute !important;
            right: 2%;
            top: 32%;
        }
    }
    }
    .form1{
        padding: 28px 180px 0 28px;
        width: 690px;
        margin: 0 auto;
        .rate{
            width: 86%;
        }
        .choiceRadioPer{
            margin-bottom: 30px;
        }
        .radio{
            min-width: 88px;
            height: 32px;
        }
        .nowAccount{
            font-size: 18px;
            font-family: Microsoft YaHei, Microsoft YaHei-Regular;
            color: #333333;
            line-height: 24px;
            text-align: center;
            margin-bottom: 30px;
        }
    }
    .line{
        text-align: center;
    }
    .tips{
        font-size: 0.73rem;
        margin-top: -10px;
    }
    .link{
        color: #409eff;
        vertical-align: revert;
        .iconicon2-02{
            font-size: 12px;
        }
    }

    .button {
        margin: 20px auto 0;
        text-align: center;
        border-top: 1px solid #DCDCDC;
        padding-top: 40px;
        .btn{
            width: 150px;
            height: 40px;
            margin-right: 12px;
        }
    }
</style>